import React, { useEffect, useState } from "react";
import { Services, UserFeed, Steps } from "@/store/state";
import {
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { UserPostCard } from "./user-post-card";
import { Badge } from "./ui/badge";
import Icon from "./icon";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import { Skeleton } from "@/components/ui/skeleton";
import actions from "@/store/actions";
import { CustomAlert } from "./custom-alert";
import { useNotify } from "@/hooks/useNotify";
import { cn } from "@/lib/utils";
import { Separator } from "./ui/separator";
import { priceList } from "@/config";

interface ServiceSpecificContentProps {
  service: Services;
  onClose: () => void;
  onBack: () => void;
}

export function ServiceSpecificContent({
  service,
  onClose,
  onBack,
}: ServiceSpecificContentProps) {
  const { userFeed, selectedPostIds, selectedPackId, cart, user, currentStep } =
    useSnapshot(state);
  const { notify } = useNotify();
  const [initialSelectedPostIds, setInitialSelectedPostIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (currentStep === Steps.CHECKOUT) {
      setInitialSelectedPostIds([...selectedPostIds]);
    }
  }, []);

  const selectedPack = selectedPackId
    ? priceList[service].find((pack) => pack.id === selectedPackId)
    : null;

  // Calculate total price: use pack price for checkout, or multiply by selected posts count otherwise
  const totalPrice = selectedPack
    ? selectedPack.price * selectedPostIds.length
    : 0;

  const handleSelectAll = () => {
    if (selectedPostIds.length === userFeed?.items.length) {
      actions.deselectAllPosts();
    } else {
      actions.selectAllPosts();
    }
  };

  const handleBack = () => {
    onBack();
    actions.resetServiceSpecificState();
  };

  const handleAddOrUpdateBasket = () => {
    if (currentStep === Steps.CHECKOUT) {
      const postsToRemove = initialSelectedPostIds.filter(
        (id) => !selectedPostIds.includes(id)
      );
      const postsToAdd = selectedPostIds.filter(
        (id) => !initialSelectedPostIds.includes(id)
      );

      if (selectedPackId) {
        actions.updateBasketInCheckout(
          service,
          selectedPackId.toString(),
          postsToRemove,
          postsToAdd
        );
        notify("Success! 🚀", `Your basket has been updated for ${service}.`, {
          variant: "green",
        });
      }
    } else {
      actions.handleAddToBasket();
      notify(
        "Success! 🚀",
        `+${selectedPostIds.length} posts have been added to your basket.`,
        { variant: "green" }
      );
    }
    actions.resetServiceSpecificState();
    onClose();
  };

  const getServiceTitle = (service: Services): string => {
    return service.toLowerCase();
  };

  const renderContent = () => {
    if (userFeed?.error) {
      return (
        <DrawerHeader className="flex items-center justify-center h-full gap-0">
          <DrawerDescription>Error loading user feed</DrawerDescription>
        </DrawerHeader>
      );
    }

    if (!userFeed) {
      return (
        <div className="px-6 overflow-y-auto">
          <div className="grid grid-cols-3 gap-2 pt-4 pb-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} className="w-full h-[200px] rounded-md" />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className="px-6 overflow-y-auto">
        <Separator />
        <div className="grid grid-cols-3 gap-2 pt-4 pb-4">
          {userFeed.items.map((item) => (
            <UserPostCard key={item.id} {...item} />
          ))}
        </div>
      </div>
    );
  };

  const isAllSelected = selectedPostIds.length === userFeed?.items.length;

  const basketButtonText =
    currentStep === Steps.CHOOSE_SERVICE ? "Add to Basket" : "Update Basket";

  return (
    <>
      <DrawerHeader className="flex items-center justify-between px-6 py-3 gap-0">
        <div
          className="border mr-3 w-7 h-7 border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
          onClick={handleBack}
        >
          <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
        </div>
        <div className="flex-grow text-center">
          <DrawerTitle className="!text-body-sm-m text-left text-gray-900">
            Select Post
          </DrawerTitle>
          <DrawerDescription className="!text-body-2xs-r text-gray-400 text-left">
            Select your posts to add {service}.
          </DrawerDescription>
        </div>
        <Badge
          className={cn(
            "px-2 py-1 rounded-full border h-[28px] cursor-pointer !text-body-2xs-m !font-[500] shadow-[0px_0px_0px_1px_var(--Gray-100,#F4F4F5)]",
            isAllSelected
              ? "border-white !bg-green-300 text-white rounded-[32px]"
              : "border-white !bg-gray-100 text-gray-900"
          )}
          onClick={handleSelectAll}
        >
          {isAllSelected ? "All Selected" : "Select All"}
          <Icon
            icon={
              isAllSelected
                ? "checkbox-circle-fill"
                : "checkbox-blank-circle-line"
            }
            className="ml-1 w-4 h-4"
          />
        </Badge>
      </DrawerHeader>
      {renderContent()}
      <Separator />
      <DrawerFooter
        className={cn(
          "bg-white max-h-[64px]",
          selectedPostIds.length === 0 ? "px-4 py-2" : "px-4 py-3"
        )}
      >
        {selectedPostIds.length === 0 && currentStep !== Steps.CHECKOUT ? (
          <CustomAlert
            variant="turquoise"
            title={`Select the posts you want to add ${getServiceTitle(
              service
            )} to...`}
            description="Select at least one post, or choose multiple."
          />
        ) : (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="text-body-xs-m text-gray-900">
                ${totalPrice.toFixed(2)}
              </span>
              <Separator orientation="vertical" className="h-5 mx-2" />
              <Badge
                variant="secondary"
                className="py-1 rounded-[78px] border border-gray-100 !text-purple-300 text-body-2xs-m bg-white"
              >
                {selectedPostIds.length} Posts Selected
              </Badge>
            </div>
            <Button className="w-auto" onClick={handleAddOrUpdateBasket}>
              {basketButtonText}
            </Button>
          </div>
        )}
      </DrawerFooter>
    </>
  );
}
