import { Services } from "@/store/state";

interface ServicePack {
  id: number;
  title: string;
  price: number;
  originalPrice: number;
  amount: number;
  discount?: number;
  promoted?: boolean;
}

export const priceList: Record<Services, ServicePack[]> = {
  followers: [
    {
      id: 15,
      title: "100 Followers",
      price: 4.99,
      originalPrice: 4.99,
      amount: 100,
    },
    {
      id: 16,
      title: "250 Followers",
      price: 6.99,
      originalPrice: 12.48,
      discount: 44,
      amount: 250,
    },
    {
      id: 17,
      title: "500 Followers",
      price: 9.99,
      originalPrice: 24.98,
      discount: 60,
      amount: 500,
    },
    {
      id: 3693,
      title: "1,000 Followers",
      price: 24.99,
      originalPrice: 62.48,
      discount: 60,
      amount: 1000,
      promoted: true,
    },
    {
      id: 19,
      title: "2,500 Followers",
      price: 34.99,
      originalPrice: 124.96,
      discount: 72,
      amount: 2500,
    },
    {
      id: 20,
      title: "5,000 Followers",
      price: 59.99,
      originalPrice: 249.96,
      discount: 76,
      amount: 5000,
    },
    {
      id: 210,
      title: "10,000 Followers",
      price: 99.99,
      originalPrice: 499.95,
      discount: 80,
      amount: 10000,
    },
    {
      id: 315,
      title: "20,000 Followers",
      price: 189.99,
      originalPrice: 1266.6,
      discount: 85,
      amount: 20000,
    },
    {
      id: 5159,
      title: "50,000 Followers",
      price: 449.99,
      originalPrice: 4499.9,
      discount: 90,
      amount: 50000,
    },
  ],
  likes: [
    {
      id: 29,
      title: "100 Likes",
      price: 3.99,
      originalPrice: 3.99,
      amount: 100,
    },
    {
      id: 30,
      title: "250 Likes",
      price: 6.99,
      originalPrice: 9.99,
      discount: 30,
      amount: 250,
    },
    {
      id: 31,
      title: "500 Likes",
      price: 9.99,
      originalPrice: 19.98,
      discount: 50,
      amount: 500,
    },
    {
      id: 32,
      title: "1,000 Likes",
      price: 14.99,
      originalPrice: 39.45,
      discount: 62,
      amount: 1000,
      promoted: true,
    },
    {
      id: 33,
      title: "2,500 Likes",
      price: 29.99,
      originalPrice: 99.97,
      discount: 70,
      amount: 2500,
    },
    {
      id: 34,
      title: "5,000 Likes",
      price: 49.99,
      originalPrice: 199.96,
      discount: 75,
      amount: 5000,
    },
    {
      id: 209,
      title: "10,000 Likes",
      price: 89.99,
      originalPrice: 391.26,
      discount: 77,
      amount: 10000,
    },
    {
      id: 3564,
      title: "20,000 Likes",
      price: 159.99,
      originalPrice: 799.95,
      discount: 80,
      amount: 20000,
    },
  ],
  views: [
    {
      id: 43,
      title: "500 Views",
      price: 2.99,
      originalPrice: 2.99,
      amount: 500,
    },
    {
      id: 44,
      title: "1,000 Views",
      price: 4.99,
      originalPrice: 6.01,
      discount: 17,
      amount: 1000,
    },
    {
      id: 45,
      title: "2,500 Views",
      price: 7.99,
      originalPrice: 15.08,
      discount: 47,
      amount: 2500,
    },
    {
      id: 46,
      title: "5,000 Views",
      price: 9.99,
      originalPrice: 30.27,
      discount: 67,
      amount: 5000,
    },
    {
      id: 47,
      title: "10,000 Views",
      price: 14.99,
      originalPrice: 59.96,
      discount: 75,
      amount: 10000,
      promoted: true,
    },
    {
      id: 48,
      title: "20,000 Views",
      price: 19.99,
      originalPrice: 117.59,
      discount: 83,
      amount: 20000,
    },
    {
      id: 208,
      title: "50,000 Views",
      price: 39.99,
      originalPrice: 307.62,
      discount: 87,
      amount: 50000,
    },
    {
      id: 2788,
      title: "100,000 Views",
      price: 69.99,
      originalPrice: 583.25,
      discount: 88,
      amount: 100000,
    },
    {
      id: 2790,
      title: "250,000 Views",
      price: 129.99,
      originalPrice: 1444.33,
      discount: 91,
      amount: 250000,
    },
    {
      id: 2791,
      title: "500,000 Views",
      price: 239.99,
      originalPrice: 2999.88,
      discount: 92,
      amount: 500000,
    },
  ],
  shares: [
    {
      id: 36,
      title: "100 Shares",
      price: 2.49,
      originalPrice: 2.49,
      amount: 100,
    },
    {
      id: 37,
      title: "250 Shares",
      price: 3.99,
      originalPrice: 6.23,
      discount: 36,
      amount: 250,
    },
    {
      id: 38,
      title: "500 Shares",
      price: 4.99,
      originalPrice: 12.48,
      discount: 60,
      amount: 500,
    },
    {
      id: 39,
      title: "1,000 Shares",
      price: 9.99,
      originalPrice: 24.98,
      discount: 60,
      amount: 1000,
      promoted: true,
    },
    {
      id: 40,
      title: "2,500 Shares",
      price: 19.99,
      originalPrice: 62.47,
      discount: 68,
      amount: 2500,
    },
    {
      id: 41,
      title: "5,000 Shares",
      price: 34.99,
      originalPrice: 124.96,
      discount: 72,
      amount: 5000,
    },
  ],
};

export const policies = [
  {
    title: "Terms",
    url: "https://www.famepeak.io/policiesv2/terms_of_service",
  },
  {
    title: "Privacy",
    url: "https://www.famepeak.io/policiesv2/privacy_policy",
  },
  {
    title: "Refund",
    url: "https://www.famepeak.io/policiesv2/refund_policy",
  },
];
