import { proxy, subscribe } from "valtio";
import { DrawerState } from "@/types/drawer-states";

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  profilePicture: string;
  totalFollower: number;
  totalLike: number;
  service: number;
  accountPrivacy: number;
  secuid: string;
  timeout: boolean;
  error: boolean;
}

export interface IpInfo {
  ip: string;
  ip_decimal: number;
  country: string;
  country_iso: string;
  country_eu: boolean;
  region_name: string;
  region_code: string;
  zip_code: string;
  city: string;
  latitude: number;
  longitude: number;
  time_zone: string;
  asn: string;
  asn_org: string;
}

export interface AccessTokenResponse {
  lastGeneratedData: number;
  accessTokenKey: string;
}

export const Steps = {
  SELECT_ACCOUNT: "SELECT_ACCOUNT",
  CHOOSE_SERVICE: "CHOOSE_SERVICE",
  CHECKOUT: "CHECKOUT",
  PAYMENT: "PAYMENT",
} as const;

export enum Services {
  FOLLOWERS = "followers",
  LIKES = "likes",
  VIEWS = "views",
  SHARES = "shares",
}

export type Step = (typeof Steps)[keyof typeof Steps];

export interface SelectedService {
  service: Services;
  amount: number;
}

export interface FeedItem {
  id: string;
  videoCover: string;
  videoDesc: string;
  playCount: number;
  diggCount: number;
  shareCount: number;
  commentCount: number;
  isSelected: boolean;
}

export interface UserFeed {
  error: boolean;
  errorCode: number;
  service: number;
  items: FeedItem[];
}

export type Followers = {
  service_id: string;
  amount: number;
  count: number;
};

export interface PostBasedProduct {
  service_id: string;
  amount: number;
  posts: ReadonlyArray<string>;
  // count özelliği kaldırıldı
}

export type Products = {
  [key in Services]: ReadonlyArray<Followers | PostBasedProduct>;
};

export type UserCart = {
  username: string;
  products: Products;
};

export enum OrderStatusEnum {
  RECEIVED = "received",
  PROCESSING = "processing",
  COMPLETED = "completed",
}

export interface OrderStatus {
  username: string;
  products: Products;
  total: number;
  error: number;
  errorText: string;
  status: OrderStatusEnum;
  orderId: string;
}

export type Cart = UserCart[];

export enum CheckoutSubStep {
  BASKET = "BASKET",
  PAYMENT = "PAYMENT",
}

interface State {
  user: User | null;
  isLoading: boolean;
  error: string | null;
  feedError: string | null;
  accessToken: string | null;
  lastGeneratedData: number | null;
  ipInfo: IpInfo | null;
  currentStep: Step;
  currentSelectedService: Services | null;
  lastAddedService: {
    service: Services | null;
    amount: number;
  };
  userFeed: UserFeed | null;
  selectedPostIds: string[];
  drawerState: DrawerState;
  selectedPackId: number | null;
  showBasketSummary: boolean;
  cart: Cart;
  checkoutSubStep: CheckoutSubStep;
}

const localStorageKey = "app_state";

const initialState: State = {
  user: null,
  isLoading: false,
  error: null,
  feedError: null,
  accessToken: null,
  lastGeneratedData: null,
  ipInfo: null,
  currentStep: Steps.SELECT_ACCOUNT,
  currentSelectedService: null,
  lastAddedService: {
    service: null,
    amount: 0,
  },
  userFeed: null,
  selectedPostIds: [],
  drawerState: DrawerState.CLOSED,
  selectedPackId: null,
  showBasketSummary: false,
  cart: [],
  checkoutSubStep: CheckoutSubStep.BASKET,
};

// Load initial state from localStorage or use default values
const loadInitialState = (): State => {
  const savedState = localStorage.getItem(localStorageKey);
  if (savedState) {
    try {
      return { ...initialState, ...JSON.parse(savedState) } as State;
    } catch (error) {
      console.error("Error parsing saved state:", error);
    }
  }
  return initialState;
};

const state = proxy<State>(loadInitialState());

// Subscribe to state changes and save to localStorage
subscribe(state, () => {
  localStorage.setItem(localStorageKey, JSON.stringify(state));
});

export default state;
