import React from "react";
import { useSnapshot } from "valtio";
import state, { CheckoutSubStep, Steps } from "@/store/state";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { X } from "lucide-react";
import { policies } from "@/config";

interface PolicyDialogProps {
  title: string;
  url: string;
}

export function PolicyDialog({ title, url }: PolicyDialogProps) {
  return (
    <Dialog>
      <DialogTrigger className="text-gray-400 text-detail hover:text-purple-300 transition-colors">
        {title}
      </DialogTrigger>
      <DialogContent className="w-full max-w-3xl h-[80vh] p-0">
        <div className="relative w-full h-full">
          <DialogClose className="absolute right-4 top-4 z-10 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
          <iframe
            src={url}
            title={title}
            className="w-full h-full border-none"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function Policies() {
  const { showBasketSummary, currentStep, checkoutSubStep } =
    useSnapshot(state);

  const isPaymentStep =
    currentStep === Steps.CHECKOUT &&
    checkoutSubStep === CheckoutSubStep.PAYMENT;

  return (
    <div
      className={`
      fixed left-0 right-0 bg-white border-t border-gray-100 text-center h-12 flex items-center justify-center gap-8 w-full
      ${
        isPaymentStep
          ? "!block bg-inherit border-none"
          : showBasketSummary
          ? "bottom-[64px] border-b border-b-gray-100"
          : "bottom-0"
      }
      z-50 transition-all duration-300 ease-in-out
    `}
    >
      <p className="text-gray-400 text-detail">
        {policies.map((policy, index) => (
          <React.Fragment key={policy.title}>
            <PolicyDialog title={policy.title} url={policy.url} />
            {index < policies.length - 1 && " · "}
          </React.Fragment>
        ))}
        {" · "}
        <a
          href="mailto:famepeakio@gmail.com?subject=Famepeak Support Request"
          className="text-gray-400 text-detail hover:text-purple-300 transition-colors"
        >
          Contact
        </a>
      </p>
    </div>
  );
}
