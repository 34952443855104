/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Lock, Shield, Zap, ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Separator } from "../ui/separator";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import PaymentCardIcons from "./payment-card-icons";
import { useSnapshot } from "valtio";
import state, {
  Followers,
  PostBasedProduct,
  Services,
  Steps,
} from "@/store/state";
import { policies, priceList } from "@/config";
import Icon from "../icon";
import PaymentFailedAlert from "./payment-failed-alert";
import { countries } from "@/config/countries";
import { parse, isAfter } from "date-fns";
import { useNotify } from "@/hooks/useNotify";
import actions from "@/store/actions";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useDebounce } from "@uidotdev/usehooks";
import { trackPurchase, trackStartProcessPayment } from "@/utils/analytics";
import { Policies, PolicyDialog } from "../policies";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  cardNumber: z
    .string()
    .regex(/^(\d{4}\s?){3}\d{4}$/, { message: "Invalid card number" }),
  expirationDate: z
    .string()
    .regex(/^(0[1-9]|1[0-2])\/\d{2}$/, { message: "Invalid expiration date" })
    .refine(
      (value) => {
        const [month, year] = value.split("/");
        const expirationDate = parse(
          `20${year}-${month}-01`,
          "yyyy-MM-dd",
          new Date()
        );
        return isAfter(expirationDate, new Date());
      },
      { message: "Invalid expiration date" }
    ),
  cvv: z.string().regex(/^\d{3,4}$/, { message: "Invalid CVV" }),
  nameOnCard: z.string().min(1, { message: "Name is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  zipCode: z.string().min(1, { message: "ZIP/Postal code is required" }),
  couponCode: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

const useCardNumberMask = () => {
  return (value: string) => {
    const input = value.replace(/\D/g, "");
    return input
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .trim()
      .slice(0, 19);
  };
};

const useExpirationDateMask = () => {
  return (value: string) => {
    const cleanedValue = value.replace(/[^\d]/g, "").slice(0, 4);
    if (cleanedValue.length >= 2) {
      return `${cleanedValue.slice(0, 2)}/${cleanedValue.slice(2)}`;
    }
    return cleanedValue;
  };
};

export interface CouponData {
  code: string;
  amount: string;
}
interface PaymentFormProps {
  couponData?: CouponData | null;
  onApplyCoupon: (code: string) => Promise<void>;
  onRemoveCoupon: () => void;
  hasCouponError?: boolean;
}

// Yeni hata mesajı bileşeni
const ErrorMessage: React.FC<{ message?: string }> = ({ message }) => {
  if (!message) return null;
  return (
    <div className="text-red-300 animate-enter text-detail mt-1 flex items-center gap-[2px] text-center mx-auto">
      <Icon icon="error-warning-fill" className="w-4 h-4" />
      <span>{message}</span>
    </div>
  );
};

interface PaymentResponse {
  orderRef: string;
  threeDRequired: boolean;
  threeDAuthUrl?: string;
}

interface RadarSessionResult {
  error?: any;
  radarSession?: {
    id: string;
  };
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  couponData,
  onApplyCoupon,
  onRemoveCoupon,
  hasCouponError,
}) => {
  const { cart, ipInfo, isLoading } = useSnapshot(state);
  const [isPaymentFailedAlertOpen, setIsPaymentFailedAlertOpen] =
    useState(false);
  const [accordionValue, setAccordionValue] = useState<string | undefined>(
    couponData ? "coupon" : undefined
  );
  const { notify } = useNotify();
  const [isThreeDSecureDrawerOpen, setIsThreeDSecureDrawerOpen] =
    useState(false);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState("");
  const navigate = useNavigate();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>("");
  const [protectorId, setProtectorId] = useState<string | null>(null);

  const [triggerFix, setTriggerFix] = useState(0);
  const debouncedTriggerFix = useDebounce(triggerFix, 1000);

  const [isProtectorIdLoading, setIsProtectorIdLoading] = useState(false);
  const [isProtectorIdReady, setIsProtectorIdReady] = useState(false);

  const fixWebsite = useCallback(() => {
    if (protectorId === null && window[atob("U3RyaXBl") as any]) {
      setIsProtectorIdLoading(true);
      const fixerName = atob("U3RyaXBl");
      const fixer = (window as any)[fixerName](
        "pk_live_51KMcigBYrriSbnaRQxlimEbWKiYHzhl97v8AK103nogI0o37f34Smjpmd0wi7KaUiCOBtFFfwXbRgSvmyTxRIFVI00FFHh4QYZ",
        {
          canCreateRadarSession: true,
        }
      );
      fixer.createRadarSession().then(function (result: RadarSessionResult) {
        if (result.error) {
          console.error(result.error);
          setIsProtectorIdLoading(false);
        } else if (result.radarSession?.id) {
          setProtectorId(result.radarSession.id);
          setIsProtectorIdReady(true);
          setIsProtectorIdLoading(false);
        }
      });
    }
  }, [protectorId]);

  const fixMe = useCallback(() => {
    if (protectorId === null) {
      console.log("Fixing website... ");
      if (!window[atob("U3RyaXBl") as any]) {
        const script = document.createElement("script");
        script.src = atob("aHR0cHM6Ly9qcy5zdHJpcGUuY29tL3YzLw==");
        document.getElementsByTagName("head")[0].appendChild(script);
        script.onload = () => {
          fixWebsite();
        };
      } else {
        fixWebsite();
      }
    }
  }, [fixWebsite, protectorId]);

  useEffect(() => {
    if (debouncedTriggerFix > 0 && protectorId === null) {
      fixMe();
    }
  }, [debouncedTriggerFix, fixMe, protectorId]);

  useEffect(() => {
    if (protectorId !== null) {
      console.log("protectorId set:", protectorId);
      setIsProtectorIdReady(true);
      setIsProtectorIdLoading(false);
    }
  }, [protectorId]);

  const triggerFixMe = () => {
    setTriggerFix((prev) => prev + 1);
  };

  const { totalAmount, totalSavings, discountedTotal, couponDiscount } =
    useMemo(() => {
      let originalTotal = 0;
      let discountedTotal = 0;

      cart.forEach((userCart) => {
        Object.entries(userCart.products).forEach(([service, products]) => {
          products.forEach((product) => {
            const packInfo = priceList[service as Services].find(
              (pack) => pack.id.toString() === product.service_id
            );
            if (packInfo) {
              if (service === Services.FOLLOWERS) {
                const followerProduct = product as Followers;
                originalTotal += packInfo.originalPrice * followerProduct.count;
                discountedTotal += packInfo.price * followerProduct.count;
              } else {
                const postBasedProduct = product as PostBasedProduct;
                const postCount = postBasedProduct.posts.length;
                originalTotal += packInfo.originalPrice * postCount;
                discountedTotal += packInfo.price * postCount;
              }
            }
          });
        });
      });

      // Apply coupon discount if available
      let couponDiscount = 0;
      if (couponData) {
        const discountPercentage = parseFloat(couponData.amount) / 100;
        couponDiscount = discountedTotal * discountPercentage;
        discountedTotal -= couponDiscount;
      }

      return {
        totalAmount: discountedTotal.toFixed(2),
        totalSavings: (originalTotal - discountedTotal).toFixed(2),
        discountedTotal: discountedTotal,
        couponDiscount: couponDiscount.toFixed(2),
      };
    }, [cart, couponData]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      cardNumber: "",
      expirationDate: "",
      cvv: "",
      nameOnCard: "",
      country: ipInfo?.country_iso || "",
      zipCode: ipInfo?.zip_code || "",
      couponCode: couponData?.code || "",
    },
  });

  const formatCardNumber = useCardNumberMask();
  const formatExpirationDate = useExpirationDateMask();

  const handlePaymentConfirmation = async (
    paymentIntent: string,
    email: string
  ) => {
    setIsThreeDSecureDrawerOpen(false);
    try {
      const result = await actions.confirmPayment(paymentIntent);
      if (result.success) {
        notify("Success", "Payment processed successfully!", {
          variant: "green",
        });

        trackPurchase(paymentIntent, email, parseFloat(totalAmount));

        navigate(`/order/orderStatus?orderID=${paymentIntent}`);
        actions.resetCart();
        actions.updateCurrentStep(Steps.CHOOSE_SERVICE);
      } else {
        setPaymentErrorMessage(result.message);
        setIsPaymentFailedAlertOpen(true);
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      setPaymentErrorMessage("An error occurred while confirming the payment.");
      setIsPaymentFailedAlertOpen(true);
    }
  };

  const location = useLocation();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const onSubmit = async (data: FormValues) => {
    try {
      trackStartProcessPayment();
      const paymentData = {
        email: data.email,
        cardNumber: data.cardNumber.replace(/\s/g, ""),
        expirationDate: data.expirationDate,
        cvv: data.cvv,
        fullName: data.nameOnCard,
        country: data.country,
        zipCode: data.zipCode,
        amount: parseFloat(totalAmount),
        coupon: couponData?.code,
        queryParams: queryParams,
        ...(import.meta.env.PROD && protectorId && { protectorId }),
      };

      notify(
        "Processing your payment…",
        "Please wait while we complete your payment.",
        { variant: "blue", icon: "loader-line" }
      );

      const response = (await actions.processPayment(
        paymentData
      )) as PaymentResponse;
      console.log("Payment response:", response);

      if (response.threeDRequired && response.threeDAuthUrl) {
        setThreeDSecureUrl(response.threeDAuthUrl);
        setIsThreeDSecureDrawerOpen(true);
      } else {
        handlePaymentConfirmation(response.orderRef, data.email);
      }
    } catch (error) {
      console.error("Payment processing failed:", error);
      setPaymentErrorMessage(
        "Your credit card could not be processed. Please check your card details and try again or contact your bank for assistance."
      );
      setIsPaymentFailedAlertOpen(true);
    }
  };

  const handleCancel = () => {
    setIsPaymentFailedAlertOpen(false);
  };

  const handleApplyCoupon = async () => {
    const couponCode = form.getValues("couponCode");
    if (couponCode) {
      try {
        await onApplyCoupon(couponCode);
      } catch (error) {
        console.error("Error applying coupon:", error);
        notify("Error", "An error occurred while applying the coupon.", {
          variant: "red",
        });
      }
    }
  };

  const handleRemoveCoupon = () => {
    form.setValue("couponCode", "");
    onRemoveCoupon();
  };

  const [emailSuggestions, setEmailSuggestions] = useState<string[]>([]);

  const domains = [
    "yahoo.com",
    "gmail.com",
    "google.com",
    "hotmail.com",
    "me.com",
    "aol.com",
    "mac.com",
    "live.com",
    "comcast.com",
    "googlemail.com",
    "msn.com",
    "hotmail.co.uk",
    "yahoo.co.uk",
    "facebook.com",
    "verizon.net",
    "att.net",
    "gmx.com",
    "mail.com",
  ];

  const updateEmailSuggestions = (value: string) => {
    if (value.indexOf("@") !== -1) {
      const [localPart] = value.split("@");
      const newSuggestions = domains.map((domain) => `${localPart}@${domain}`);
      setEmailSuggestions(newSuggestions);
    } else {
      setEmailSuggestions([]);
    }
  };

  return (
    <div className="w-full md:h-[75vh] md:overflow-y-auto" id="payment-form">
      <Card
        className="border mx-6 border-gray-100 text-white mb-4 rounded-[12px] overflow-hidden"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(0deg, var(--Green-300, #38C793) 0%, var(--Green-300, #38C793) 100%), var(--White, #FFF)",
        }}
      >
        <CardContent className="px-4 py-[14px]">
          <h2 className="mb-3 text-body-2xs-m text-center">Payment Summary</h2>
          <div className="flex justify-center">
            <div
              className="max-w-[173px] flex justify-center border border-white/10 rounded-[6px] px-3 py-1 mb-3"
              style={{
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Green-300, #38C793)",
                boxShadow: "0px 1px 2px 0px rgba(45, 159, 117, 0.56)",
              }}
            >
              <div className="flex justify-between items-center">
                <span className="text-body-2xs-r text-green-200">
                  Total Amount
                </span>
                <Separator
                  orientation="vertical"
                  className="h-3 mx-4 !bg-white/10"
                />
                <span className="text-body-xs-m text-white">
                  ${totalAmount}
                </span>
              </div>
            </div>
          </div>
          <div className="gap-1 flex items-center justify-center">
            <span className="py-[2px] text-green-200 text-body-2xs-r text-center">
              You are saving{" "}
              <span className="text-white">${totalSavings} </span> on this
              purchase.
            </span>
            <span>🤑</span>
          </div>
        </CardContent>
      </Card>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
          <div className="mx-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-1">
                  <FormLabel className="!text-gray-500 pl-1 text-body-2xs-m">
                    Email
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        {...field}
                        className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r ${
                          fieldState.error
                            ? "border-red-300"
                            : "border-gray-200"
                        }`}
                        placeholder="you@example.com"
                        list="email-options"
                        onChange={(e) => {
                          field.onChange(e);
                          triggerFixMe();
                        }}
                      />
                      <datalist id="email-options">
                        {emailSuggestions.map((suggestion, index) => (
                          <option key={index} value={suggestion} />
                        ))}
                      </datalist>
                    </div>
                  </FormControl>
                  <ErrorMessage message={fieldState.error?.message} />
                </FormItem>
              )}
            />
            <div className="space-y-1 mt-3">
              <h3 className="text-gray-500 pl-1 text-body-2xs-m">
                Card Details
              </h3>
              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="cardNumber"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormControl>
                        <div className="relative">
                          <Input
                            type="tel"
                            inputMode="numeric"
                            className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r pr-24 ${
                              fieldState.error
                                ? "border-red-300"
                                : "border-gray-200"
                            }`}
                            autoComplete="cc-number"
                            placeholder="1234-1234-1234-1234"
                            {...field}
                            onChange={(e) => {
                              const formattedValue = formatCardNumber(
                                e.target.value
                              );
                              field.onChange(formattedValue);
                              e.target.value = formattedValue.replace(
                                /\s/g,
                                ""
                              );
                              triggerFixMe();
                            }}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={19}
                          />
                          <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                            <PaymentCardIcons />
                          </div>
                        </div>
                      </FormControl>
                      <ErrorMessage message={fieldState.error?.message} />
                    </FormItem>
                  )}
                />

                <div className="flex space-x-3">
                  <FormField
                    control={form.control}
                    name="expirationDate"
                    render={({ field, fieldState }) => (
                      <FormItem className="w-1/2 space-y-1">
                        <FormControl>
                          <Input
                            type="tel"
                            inputMode="numeric"
                            className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r ${
                              fieldState.error
                                ? "border-red-300"
                                : "border-gray-200"
                            }`}
                            placeholder="MM/YY"
                            {...field}
                            onChange={(e) => {
                              const formattedValue = formatExpirationDate(
                                e.target.value
                              );
                              field.onChange(formattedValue);
                              e.target.value = formattedValue;
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "Backspace" &&
                                field.value.endsWith("/")
                              ) {
                                e.preventDefault();
                                field.onChange(field.value.slice(0, -2));
                              }
                            }}
                            maxLength={5}
                          />
                        </FormControl>
                        <ErrorMessage message={fieldState.error?.message} />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="cvv"
                    render={({ field, fieldState }) => (
                      <FormItem className="w-1/2 space-y-1">
                        <FormControl>
                          <Input
                            type="number"
                            className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r ${
                              fieldState.error
                                ? "border-red-300"
                                : "border-gray-200"
                            }`}
                            placeholder="CVV"
                            {...field}
                            onChange={(e) => {
                              const value = e.target.value.slice(0, 4);
                              field.onChange(value);
                              e.target.value = value;
                              triggerFixMe();
                            }}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={4}
                          />
                        </FormControl>
                        <ErrorMessage message={fieldState.error?.message} />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <FormField
              control={form.control}
              name="nameOnCard"
              render={({ field, fieldState }) => (
                <FormItem className="space-y-1 mt-3">
                  <FormLabel className="!text-gray-500 pl-1 text-body-2xs-m">
                    Name on Card
                  </FormLabel>
                  <FormControl>
                    <Input
                      className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r ${
                        fieldState.error ? "border-red-300" : "border-gray-200"
                      }`}
                      placeholder="Enter name as it appears on your card"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        triggerFixMe();
                      }}
                    />
                  </FormControl>
                  <ErrorMessage message={fieldState.error?.message} />
                </FormItem>
              )}
            />
            <div className="flex space-x-2 mt-3">
              <FormField
                control={form.control}
                name="country"
                render={({ field, fieldState }) => (
                  <FormItem className="w-1/2 space-y-1">
                    <FormLabel className="!text-gray-500 pl-1 text-body-2xs-m">
                      Country or Region
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          className={`rounded-[8px] text-body-2xs-r ${
                            fieldState.error
                              ? "border-red-300"
                              : "border-gray-200"
                          }`}
                        >
                          <SelectValue placeholder="Select a country" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {countries.map((country) => (
                          <SelectItem key={country.code} value={country.code}>
                            {country.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <ErrorMessage message={fieldState.error?.message} />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="zipCode"
                render={({ field, fieldState }) => (
                  <FormItem className="w-1/2 space-y-1">
                    <FormLabel className="!text-gray-500 pl-1 text-body-2xs-m">
                      ZIP/Postal Code
                    </FormLabel>
                    <FormControl>
                      <Input
                        className={`rounded-[8px] placeholder:text-gray-400 text-body-2xs-r ${
                          fieldState.error
                            ? "border-red-300"
                            : "border-gray-200"
                        }`}
                        placeholder="90210"
                        {...field}
                      />
                    </FormControl>
                    <ErrorMessage message={fieldState.error?.message} />
                  </FormItem>
                )}
              />
            </div>
            <Card className="bg-gray-100 border-gray-200 !mt-3 rounded-[8px]">
              <CardContent className="px-3 py-[10px]">
                <Accordion
                  type="single"
                  collapsible
                  className="w-full"
                  value={accordionValue}
                  onValueChange={setAccordionValue}
                >
                  <AccordionItem value="coupon" className="border-b-0">
                    <AccordionTrigger className="!text-gray-500 text-body-2xs-m p-0">
                      Apply coupon code
                    </AccordionTrigger>
                    <AccordionContent className="pb-0 pt-3">
                      <FormField
                        control={form.control}
                        name="couponCode"
                        render={({ field, fieldState }) => (
                          <FormItem className="space-y-1">
                            <FormControl className="">
                              <div className="flex space-x-2 relative h-full">
                                <div className="relative sm:w-full md:max-w-full">
                                  <Input
                                    className={`rounded-[8px] h-[36px] max-w-[223px] sm:max-w-full md:max-w-full placeholder:text-gray-400 text-body-2xs-r bg-white pr-8 ${
                                      fieldState.error
                                        ? "border-red-300"
                                        : couponData
                                        ? "border-green-300"
                                        : "border-gray-200"
                                    } w-full`}
                                    placeholder="Enter coupon code"
                                    {...field}
                                  />
                                  {field.value && (
                                    <button
                                      type="button"
                                      className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                      onClick={handleRemoveCoupon}
                                    >
                                      <Icon
                                        icon="close-line"
                                        className="w-4 h-4 text-gray-400"
                                      />
                                    </button>
                                  )}
                                </div>
                                {couponData ? (
                                  <div className="shrink-0 h-[36px] border border-green-200 px-3 flex items-center justify-center text-[#8CDFC0] text-body-xs-m bg-green-100 rounded-[8px]">
                                    Applied
                                    <Icon
                                      icon="checkbox-circle-fill"
                                      className="ml-1 w-5 h-5"
                                    />
                                  </div>
                                ) : (
                                  <Button
                                    type="button"
                                    className="shrink-0 h-[36px] border-none min-w-[68px]"
                                    style={{
                                      background:
                                        "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Gray-800, #27272A)",
                                      boxShadow:
                                        "0px 1px 2px 0px var(--Gray-900, #18181B), 0px 0px 0px 1px var(--Gray-800, #27272A)",
                                    }}
                                    onClick={handleApplyCoupon}
                                  >
                                    Apply
                                  </Button>
                                )}
                              </div>
                            </FormControl>
                            <ErrorMessage message={fieldState.error?.message} />
                          </FormItem>
                        )}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardContent>
            </Card>
            {couponData && (
              <div className="mt-2 text-green-300 text-body-2xs-m flex items-center pl-3">
                <Icon
                  icon="information-fill"
                  className="w-4 h-4 mr-1 text-green-300"
                />
                <span>
                  Great news! Your ${couponDiscount} ({couponData.amount}%)
                  discount is applied. 🎉
                </span>
              </div>
            )}
            {hasCouponError && (
              <div className="mt-2 text-red-300 text-body-2xs-m flex items-center pl-3">
                <Icon
                  icon="error-warning-fill"
                  className="w-4 h-4 mr-1 text-red-300"
                />
                <span>Oops! This coupon code is not valid. 😕</span>
              </div>
            )}
            <div className="flex justify-between mt-3 gap-3">
              <div className="gap-1 flex items-center rounded-[6px] border-[0.5px] max-h-[36px] border-gray-100 bg-white pt-1 pr-[14px] pb-1 pl-[6px]">
                <Icon
                  icon="secure-payment-line"
                  className="w-5 h-5 text-yellow-300"
                />
                <span className="text-detail text-gray-500 max-h-[28px]">
                  Guaranteed Safe Checkout
                </span>
              </div>
              <div className="gap-1 flex items-center rounded-[6px] max-h-[36px] border-[0.5px] border-gray-100 bg-white pt-1 pr-[14px] pb-1 pl-[6px]">
                <Icon
                  icon="secure-payment-line"
                  className="w-5 h-5 text-yellow-300"
                />
                <span className="text-detail text-gray-500">
                  Secure SSL Encryption
                </span>
              </div>
              <div className="gap-1 flex items-center rounded-[6px] max-h-[36px] border-[0.5px] border-gray-100 bg-white pt-1 pr-[14px] pb-1 pl-[6px]">
                <Icon icon="service-line" className="w-5 h-5 text-yellow-300" />
                <span className="text-detail text-gray-500">
                  Performance Guaranteed
                </span>
              </div>
            </div>
            <div className="text-detail flex justify-center items-center mx-auto gap-2 text-gray-400 mt-8 h-6">
              {policies.map((policy, index) => (
                <React.Fragment key={policy.title}>
                  <PolicyDialog title={policy.title} url={policy.url} />
                  {index < policies.length - 1 && " · "}
                </React.Fragment>
              ))}
              {" · "}
              <a href="mailto:famepeakio@gmail.com?subject=Famepeak Support Request">
                Contact
              </a>
            </div>
          </div>
          <div
            className="px-4 max-w-2xl mx-auto py-3 border-t border-gray-100 bg-white sticky md:fixed bottom-0 left-0 right-0"
            id="payment-form-pay-btn"
          >
            <Button
              type="submit"
              className="mx-auto w-full bg-white border-t border-gray-100"
              loading={isLoading || isProtectorIdLoading}
            >
              {`Pay $${totalAmount}`}
            </Button>
          </div>
        </form>
      </Form>

      <Drawer
        setBackgroundColorOnScale={false}
        open={isThreeDSecureDrawerOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsThreeDSecureDrawerOpen(false);
          }
        }}
      >
        <DrawerContent className="h-[95%] max-w-2xl mx-auto">
          <DrawerHeader>
            <DrawerTitle>3D Secure Verification</DrawerTitle>
          </DrawerHeader>
          <div className="w-full h-[calc(100vh-4rem)]">
            <iframe
              src={threeDSecureUrl}
              title="3D Secure Verification"
              className="w-full h-full border-none"
              onLoad={(e) => {
                const iframe = e.target as HTMLIFrameElement;
                if (
                  iframe.contentWindow?.location.href.includes(
                    "payment_intent="
                  )
                ) {
                  const url = new URL(iframe.contentWindow.location.href);
                  const paymentIntent = url.searchParams.get("payment_intent");
                  if (paymentIntent) {
                    // Email'i form değerlerinden alıyoruz
                    const email = form.getValues("email");
                    handlePaymentConfirmation(paymentIntent, email);
                  }
                }
              }}
            />
          </div>
        </DrawerContent>
      </Drawer>

      <PaymentFailedAlert
        open={isPaymentFailedAlertOpen}
        onOpenChange={setIsPaymentFailedAlertOpen}
        onCancel={handleCancel}
        errorMessage={paymentErrorMessage}
      />
    </div>
  );
};

export default PaymentForm;
