import React, { useState, useEffect } from "react";
import { Services, Steps, Followers, PostBasedProduct } from "@/store/state";
import { useSnapshot } from "valtio";
import state from "@/store/state";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Separator } from "./ui/separator";
import { ChooseServiceGraph } from "./choose-service-graph";
import { ServicePackSelector } from "./service-pack-selector";
import { ServiceSpecificContent } from "./service-specific-content";
import { DrawerState } from "@/types/drawer-states";
import { priceList } from "@/config";
import Icon from "./icon";
import actions from "@/store/actions";
import { useNotify } from "@/hooks/useNotify";
import { useIsMobile } from "@/hooks/useIsMobile";
import { ErrorDialog } from "./ErrorDialog";
import { cn } from "@/lib/utils";

export function StatDrawer() {
  const store = useSnapshot(state);
  const { notify } = useNotify();
  const isMobile = useIsMobile();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [originalServiceId, setOriginalServiceId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (store.currentStep === Steps.CHECKOUT && store.currentSelectedService) {
      const userCart = store.cart.find(
        (cart) => cart.username === store.user!.username
      );
      if (userCart) {
        const products = userCart.products[store.currentSelectedService];
        if (products && products.length > 0) {
          if (store.currentSelectedService === Services.FOLLOWERS) {
            // For Followers, find the existing product in the cart that matches the selected pack
            const existingProduct = products.find(
              (p) =>
                (p as Followers).service_id === store.selectedPackId?.toString()
            ) as Followers | undefined;

            setOriginalServiceId(
              existingProduct ? existingProduct.service_id : null
            );
          } else {
            // For other services, find the product that matches the selected posts
            const oldProduct = products.find((p) =>
              (p as PostBasedProduct).posts.some((postId) =>
                store.selectedPostIds.includes(postId)
              )
            );
            setOriginalServiceId(oldProduct ? oldProduct.service_id : null);
          }
        } else {
          setOriginalServiceId(null);
        }
      } else {
        setOriginalServiceId(null);
      }
    }
  }, [
    store.currentStep,
    store.currentSelectedService,
    store.cart,
    store.user,
    store.selectedPostIds,
  ]);

  const handleCloseDrawer = () => {
    actions.setDrawerState(DrawerState.CLOSED);
    actions.resetServiceSpecificState();
    setOriginalServiceId(null);
  };

  const handleBack = () => {
    actions.setDrawerState(DrawerState.SERVICE_SELECTION);
  };

  const handleAddOrUpdateBasket = () => {
    if (store.currentStep === Steps.CHECKOUT) {
      updateBasket();
    } else {
      addToBasket();
    }
    handleCloseDrawer();
  };

  const handleContinue = () => {
    if (store.currentStep === Steps.CHECKOUT) {
      updateBasket();
      handleCloseDrawer();
    } else {
      const { isValid, error } = actions.checkUserFeed();
      if (!isValid) {
        setErrorMessage(error || "Unknown error");
        setShowErrorDialog(true);
        return;
      }
      actions.setDrawerState(DrawerState.SERVICE_SPECIFIC);
    }
  };

  const updateBasket = () => {
    const userCart = store.cart.find(
      (cart) => cart.username === store.user!.username
    );
    if (userCart && store.currentSelectedService && store.selectedPackId) {
      if (originalServiceId !== store.selectedPackId.toString()) {
        actions.updateCartItem(
          store.user!.username,
          store.currentSelectedService,
          originalServiceId || "",
          store.selectedPackId.toString(),
          store.selectedPostIds
        );
        notify(
          "Success! 🚀",
          `Your basket has been updated for ${store.currentSelectedService}.`,
          { variant: "green" }
        );
      } else {
        // If the service IDs are the same, we don't need to update
        notify(
          "No changes made",
          `Your selection for ${store.currentSelectedService} remains the same.`,
          { variant: "blue" }
        );
      }
    }
  };

  const addToBasket = () => {
    const selectedPack = store.selectedPackId
      ? priceList[store.currentSelectedService!].find(
          (pack) => pack.id === store.selectedPackId
        )
      : null;

    if (selectedPack) {
      actions.handleAddToBasket();
      const amount =
        store.currentSelectedService === Services.FOLLOWERS
          ? selectedPack.amount
          : selectedPack.amount * store.selectedPostIds.length;

      notify(
        "Success! 🚀",
        `+${amount.toLocaleString()} ${
          store.currentSelectedService
        } have been added to your basket.`,
        { variant: "green" }
      );
    }
  };

  const renderDrawerContent = () => {
    if (!store.currentSelectedService) return null;

    const service = store.currentSelectedService;
    const label = service.charAt(0).toUpperCase() + service.slice(1);

    if (
      store.drawerState === DrawerState.SERVICE_SPECIFIC &&
      (service === Services.LIKES ||
        service === Services.VIEWS ||
        service === Services.SHARES)
    ) {
      return (
        <ServiceSpecificContent
          service={service}
          onClose={handleCloseDrawer}
          onBack={handleBack}
        />
      );
    }

    const selectedPack = store.selectedPackId
      ? priceList[service].find((pack) => pack.id === store.selectedPackId)
      : null;

    const buttonText =
      store.currentStep === Steps.CHECKOUT
        ? "Update Basket"
        : service === Services.FOLLOWERS
        ? "Add to Basket"
        : "Continue";

    return (
      <>
        <DrawerHeader className="flex items-center gap-0">
          <div
            className="border w-7 h-7 border-gray-100 rounded-[6px] bg-gray-50 flex items-center justify-center cursor-pointer"
            onClick={handleCloseDrawer}
          >
            <Icon icon="arrow-left-line" className="w-5 h-5 text-gray-900" />
          </div>
          <div className="flex-grow">
            <DrawerTitle className="!text-body-sm-m text-center">
              How many {label.toLowerCase()} do you need?
            </DrawerTitle>
            <DrawerDescription className="!text-body-2xs-r text-gray-400 text-center">
              {service === Services.FOLLOWERS
                ? "Select a follower pack to boost your presence!"
                : service === Services.LIKES
                ? "Select how many likes to add to each post you'll select."
                : service === Services.VIEWS
                ? "Select how many views to add to each post you'll select."
                : service === Services.SHARES
                ? "Select how many shares to add to each post you'll select."
                : "Select your posts to add the " +
                  label.toLowerCase() +
                  ", all posts get."}
            </DrawerDescription>
          </div>
        </DrawerHeader>
        <div className="mx-4 inline-flex justify-self-center text-center">
          <Separator />
        </div>

        <div className="px-4">
          {store.currentSelectedService && store.user && selectedPack && (
            <ChooseServiceGraph
              service={store.currentSelectedService}
              startValue={
                store.currentSelectedService === Services.LIKES
                  ? store.user.totalLike
                  : store.user.totalFollower
              }
              endValue={
                store.currentSelectedService === Services.LIKES
                  ? store.user.totalLike + selectedPack.amount
                  : store.user.totalFollower + selectedPack.amount
              }
              className="mt-4"
            />
          )}

          <ServicePackSelector
            className="mt-4 pt-3"
            onPackSelect={(packId) => actions.setSelectedPackId(packId)}
          />
        </div>

        {/* <Separator className="mt-5" /> */}
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100">
          <DrawerFooter className="px-4 py-3">
            <Button
              className="w-full"
              disabled={store.selectedPackId === null}
              onClick={
                store.currentStep === Steps.CHECKOUT ||
                service === Services.FOLLOWERS
                  ? handleAddOrUpdateBasket
                  : handleContinue
              }
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </div>
      </>
    );
  };

  return (
    <>
      <Drawer
        open={store.drawerState !== DrawerState.CLOSED}
        onOpenChange={(isOpen) => {
          if (!isOpen) handleCloseDrawer();
        }}
        shouldScaleBackground
        setBackgroundColorOnScale={false}
      >
        <DrawerContent
          className={cn(isMobile ? "h-[95%]" : "h-[85%]", "max-w-2xl mx-auto")}
        >
          {renderDrawerContent()}
        </DrawerContent>
      </Drawer>
      <ErrorDialog
        open={showErrorDialog}
        onOpenChange={setShowErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
}
